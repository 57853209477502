.c-grid{
	position: absolute;
}

.c-grid__territory{
	position: relative;
}

.c-grid__row{
	width: auto;
	display: flex;
	flex-wrap: wrap;
	&.c-grid__row--head{
		width: auto;
		display: block;
	}
}

.c-grid__column{
	vertical-align: top;
	display: inline-block;
	padding: 1em;
	width: $col-width;
	border-left: .5em solid #FFF;
	border-right: .5em solid #FFF;
	background: $off-white;
	.c-grid__row--head &{
		font-weight: bold;
		text-align: center;
		height: 5em;
		width: $col-width;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
}

.c-grid__column--empty{
	background: none;
}