.c-amends-panel{

	.is-showing-true{
		display: block;
	}

	.is-showing-false{
		display: none;
	}

}