.c-row{
	display: grid;
	width: 100%;
	height: $row-height;
	margin: $row-margin 0;
	background: $off-white;
}

.c-row__label{
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $medium;
	position: relative;
	&:hover{
		.o-manage-select__on-hover--move, .o-manage-select__on-hover--edit{
			display: block;
		}
	}
}