.c-singular__content{
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.c-singular__panel{
	position: absolute;
	top: 0;
	transition: all 1s;
	opacity: 0;
	width: 25%;
	height: 100%;
	background: #FFF;
	z-index: 3;
	&.is-open{
		opacity: 1;
	}
}

.c-singular__panel--left{
	right: 100%;
	border-right: 1px solid $medium;
	&.is-open{
		right: 75%;
	}
}

.c-singular__panel--right{
	border-left: 1px solid $medium;
	left: 100%;
	&.is-open{
		left: 75%;
	}
}
