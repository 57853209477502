.c-settings-button{
	border-radius: 4px;
	background: #000;
	color: #FFF;
	height: 100%;
	i{
		margin-right: .5em;
		transition: all 1s;
	}
	button{
		background: #000;
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 0em 1em;
		color: #FFF;
		border: none;
		border-radius: 4px;
	}
	&:hover{
		i{
			transform: rotateZ(360deg);
		}
	}
}