.c-control-bar{
	width: 100%;
	display: flex;
	height: $control-bar__height;
	align-items: center;
	justify-content: stretch;
	padding: .5em 0;
	border-bottom: 1px solid $light;
	padding-right: 1em;
}

.c-control-bar__close{
	position: relative;
	width: $control-bar__height;
	height: 100%;
	border-right: 1px solid $light;
	cursor: pointer;
	i{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.c-control-bar__component{
	width: auto;
}