.o-toggle__switch{
	border-radius: 2em;
	display: inline-block;
	height: 2em;
	width: 3em;
	padding: .25em;
	position: relative;
	cursor: pointer;
	transition: all .25s;
	margin: 0 1em;
	&.switched-on{
		background: #000;
	}
	&.switched-off{
		background: $light;
	}
}

.o-toggle__knob{
	background: #FFF;
	border-radius: 50%;
	height: 1.5em;
	width: 1.5em;
	position: absolute;
	display: block;
	transition: all .5s;
	top: 50%;
	transform: translateY(-50%);
	.switched-on &{
		left: calc(0% + .3em);
	}
	.switched-off &{
		left: calc(100% - 1.7em);
	}
}
