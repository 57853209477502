@use 'sass:map';
@use '~@angular/material' as mat;
@import '~@angular/material/theming';
@include mat.core();
// https://stackoverflow.com/questions/60062302/how-do-you-create-an-angular-material-theme-using-only-hex-values
//http://mcg.mbitson.com/#!?mcgpalette0=%230079b9
// #0079B9
/* For use in src/lib/core/theming/_palette.scss */
$palette-primary: (
    50 : #e0eff7,
    100 : #b3d7ea,
    200 : #80bcdc,
    300 : #4da1ce,
    400 : #268dc4,
    500 : #0079b9,
    600 : #0071b2,
    700 : #0066aa,
    800 : #005ca2,
    900 : #004993,
    A100 : #bedaff,
    A200 : #8bbdff,
    A400 : #589fff,
    A700 : #3f91ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
// #BA2400
$palette-warn: (
    50 : #f7e5e0,
    100 : #eabdb3,
    200 : #dd9280,
    300 : #cf664d,
    400 : #c44526,
    500 : #ba2400,
    600 : #b32000,
    700 : #ab1b00,
    800 : #a31600,
    900 : #940d00,
    A100 : #ffc2bf,
    A200 : #ff918c,
    A400 : #ff5f59,
    A700 : #ff4740,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);
/* For use in src/lib/core/theming/_palette.scss */
// #5c5c6f
$palette-accent: (
    50 : #ebebee,
    100 : #ceced4,
    200 : #aeaeb7,
    300 : #8d8d9a,
    400 : #747485,
    500 : #5c5c6f,
    600 : #545467,
    700 : #4a4a5c,
    800 : #414152,
    900 : #303040,
    A100 : #9393fd,
    A200 : #6161fd,
    A400 : #2b2bff,
    A700 : #1212ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
$palette-green: (
    50 : #e7f5ea,
    100 : #c2e6ca,
    200 : #9ad6a7,
    300 : #71c583,
    400 : #52b869,
    500 : #34ac4e,
    600 : #2fa547,
    700 : #279b3d,
    800 : #219235,
    900 : #158225,
    A100 : #b8ffc0,
    A200 : #85ff94,
    A400 : #52ff67,
    A700 : #38ff50,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$palette-white: (
    50 : #ffffff,
    100 : #fefefe,
    200 : #fdfdfd,
    300 : #fcfcfc,
    400 : #fcfcfc,
    500 : #fbfbfb,
    600 : #fafafa,
    700 : #fafafa,
    800 : #f9f9f9,
    900 : #f8f8f8,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$my-app-green: mat.define-palette($palette-green);

/* light theme */
$primary-light: mat.define-palette($palette-primary);
$accent-light: mat.define-palette($palette-accent);//mat.define-palette(mat.$pink-palette, 500, 900, A100);
$warn-light: mat.define-palette($palette-warn);
$theme-light: mat.define-light-theme($primary-light, $accent-light, $warn-light);//,$my-app-approve);
/*
// Insert custom background color
//$background-color-light: mat.define-palette($palette-white);
$background-color-light: mat-palette($mat-grey);
$background: map-get($theme-light, background);
$background: map_merge($background, (background: $background-color-light));
$theme-light: map_merge($theme-light, (background: $background));
*/

/* light theme variables*/

$color-config:mat.get-color-config($theme-light);
$fg_palette:map-get($theme-light, foreground);
$bg_palette:map-get($theme-light, background);
$theme_fg:map-get($fg_palette, text);
$theme_bg:map-get($bg_palette, background);


$bg: var(--bg);
$fg: var(--fg);
$bg-shade-digit: var(--bg-shade-digit);
$bg-shader: var(--bg-shader);
$txt_note: var(--txt_note);


.light-theme {
    --bg:#{$theme_bg};
    --fg:#{$theme_fg};
    --bg-shade-digit:0;
    --bg-shader: black;
    --txt_note:#FF0000;
    @include mat.all-component-themes($theme-light);
    font-size: 16px !important;

    //@include spread-map($theme-light);
}



// dark theme
// https://medium.com/compendium/dynamic-themes-in-angular-material-b6dc0c88dfd7#:~:text=Setting%20the%20scene&text=%40include%20angular%2Dmaterial%2Dtheme,components%2C%20depending%20on%20your%20choice.
// https://medium.com/@lisa.berteau.smith/dont-be-left-in-the-dark-how-to-design-a-dark-mode-with-angular-material-theme-7046920af8fb
// https://trevier.medium.com/angular-material-theme-color-options-7d5968cb7460
// https://github.com/angular/components/issues/6244
// https://stackoverflow.com/questions/49642098/angular-material-global-color-variables
// https://pkief.medium.com/automatic-dark-mode-detection-in-angular-material-8342917885a0 <- todo check this approach out


$primary-dark: mat.define-palette($mat-blue-grey);
$accent-dark: mat.define-palette($mat-indigo, A400, A100, A700);
$warn-dark: mat.define-palette($mat-red);
$theme-dark: mat-dark-theme($primary-dark, $accent-dark, $warn-dark);

// Insert custom background color
/*
$background-color-dark: mat-palette($mat-grey);
$background: map-get($theme-dark, background);
$background: map_merge($background, (background: $background-color-dark));
$theme-dark: map_merge($theme-dark, (background: $background));
*/


/* dark theme variables */


$color-config:    mat.get-color-config($theme-dark);
//$background:   map.get($color-config, background);
//$background2:   mat-color($background);

$fg_palette_dark:map-get($theme-dark, foreground);
$bg_palette_dark:map-get($theme-dark, background);
$fg_dark:map-get($fg_palette_dark, text);
$bg_dark:map-get($bg_palette_dark, background);

//$dark_theme_bg_1:mat-color($bg_palette_dark);
//$dark_theme_bg_2:mat-color($bg_palette_dark, lighter);


.dark-theme {    
    --bg:#{$bg_dark};
    --fg:#{$fg_dark};
    --bg-shade-digit:255;
    --bg-shader: white;
    --txt_note:#00FF00;
    //@include angular-material-theme($dark-theme);
    color: $light-primary-text;
    @include mat.all-component-themes($theme-dark);
    //@include spread-map($theme-dark);
    font-size: 16px !important;
}

$warn:mat.get-color-from-palette(mat.define-palette($palette-warn));
$primary:mat.get-color-from-palette(mat.define-palette($palette-primary));
$accent:mat.get-color-from-palette(mat.define-palette($palette-accent));
$green:mat.get-color-from-palette(mat.define-palette($palette-green));




::ng-deep .mat-snack-bar-container {
    border-radius: 4px !important;
    box-sizing: border-box !important;
    display: flex !important;
    margin: 0px !important;
    max-width: 33vw !important;
    min-width: 344px !important;
    padding: 0px !important;
    min-height: 0px !important;
    transform-origin: center !important;
    padding: 0.5em 0.5em !important;
    font-size: 0.6em !important;
    align-items: center !important;
    justify-content: center !important;

    color: rgb(0 0 0 / 70%);
    background: #fafafa ;
}
