.c-template{
	height: 500px;
	width: 300px;
	cursor: pointer;
	display: block;
	background: $off-white;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	img{
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}

.c-template__drag-true{
	background: #000;
	animation: pulse .5s infinite alternate;
}

.c-template__drag-false{
	animation-iteration-count: 1;
}

@keyframes pulse{
	0%{
		background: #FFF;
	}
	100%{
		background: #CCC;
	}
}