.c-fit-control{
	width: auto;
	flex-grow: 1;
	text-align: center;
}


.c-fit-control__fullscreen{
	transition: transform .25s;
	&:hover{
		transform: scale(1.25);
		cursor: pointer;
	}
}
.c-fit-control__fullscreen-exit{
	transition: transform .25s;
	&:hover{
		transform: scale(1.25);
		cursor: pointer;
	}
}