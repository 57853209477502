.o-manage-select{
	position: absolute;
	right: 1em;
	top: 50%;
	transform: translateY(-50%);
	width: 2em;
	height: 2em;
	display: block;
	cursor: pointer;
	i{
		color: rgba(255,255,255,.5);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}


.o-manage-select__on-hover--move {
	display: none;
	right: auto;
	left: 1em;
	i{
		color: $black;
	}
}
.o-manage-select__on-hover--edit {
	display: none;
	i{
		color: $black;
	}
}

.o-manage-select__add{
	cursor: pointer;
	display: inline-block;
	&.c-grid__row, .c-grid span > &{
		width: 10em;
	    height: 5em;
	    align-items: center;
	    display: inline-flex;
	    justify-content: center;
	}
}