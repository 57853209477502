@use '~@angular/material' as mat;
@import '~@angular/material/theming';

@include mat.core();
@import './../../theme.scss';

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $font-family: 'Inter, Roboto, monospace',
);

@include mat.all-component-typographies($custom-typography);

body {
	//font-family: sans-serif;
	* {
		box-sizing: border-box;
	}
	#wrapper {
		display:flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		.main {
			overflow:hidden;
			flex-grow: 1;
			//overflow-y: scroll;
			//background-color: #fff;
		}
	}
	#loader {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,0.6);
		z-index: -1;
		opacity: 0;
		transition: ease-out 500ms opacity;
		#spinner {
			position: absolute;
			width: 100px;
			height: 100px;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
		}
	}
	&.loading {
		.mat-progress-spinner circle, .mat-spinner circle {
			stroke: #000;
		}
		#loader {
			opacity: 1;
			z-index: 999999999;
		}
	}
	&.saving {
		#wrapper {
			filter: blur(6px);
		}
	}
	&.reloading {
		#wrapper {
			filter: blur(6px);
		}
		.mat-progress-spinner circle, .mat-spinner circle {
			stroke: #000;
		}
		#loader {
			opacity: 1;
			z-index: 999999999;
		}
	}
	@keyframes errorshake {
	    8%, 41% {
	        transform: translate(-2%, -0%);
	    }
	    25%, 58% {
	        transform: translate(2%, -0%);
	    }
	    75% {
	        transform: translate(-1%, -0%);
	    }
	    92% {
	        transform: translate(-1%, -0%);
	    }
	    0%, 100% {
	        transform: translate(-0%, -0%);
	    }
	}
	
	.error {
		animation-name: errorshake;
		animation-duration: .6s; /* or: Xms */
		animation-iteration-count: 1;
		animation-timing-function: linear; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

		color: mat.get-color-from-palette(mat.define-palette($palette-warn)) !important;
	}

	.snackBar-error{
		animation-name: errorshake;
		animation-duration: .6s; /* or: Xms */
		animation-iteration-count: 1;
		animation-timing-function: linear; /* or: ease, ease-in, ease-in-out, linear, cubic-bezier(x1, y1, x2, y2) */

		background: mat.get-color-from-palette(mat.define-palette($palette-warn)) !important;;
    	color: white !important;
	}
	
	/* Login cards */

	.login-container {
		position: absolute;
		left: 50%;
		top: 50%;
		max-width: 400px;
		min-width: min-content;
		width: 40%;
		height: 25%;
		transform: translate(-50%, -50%);

		.login{
			height: 300px;
		}
		.button_wrapper {
			//text-align: right;
			margin-top: 2em;
			display: flex;
    		justify-content: space-between;
			flex-direction: row-reverse; //required for submit first found button on ENTER key

			button {
				&:last-of-type{
					margin-left: 0.25em;
				}
			}
			/*
			.signup_btn {
				float: left;
			}*/
		}
		.inputs {
			width: 100%;
			margin-bottom: 3em;
			p {
				text-align: center;
			}
			.mat-form-field {
				width: 100%;
			}
		}
		.register {
			text-align: center;
			font-size: 0.8em;
			color: #898989;
			a {
				cursor: pointer;
				color: #232323;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.forgot_passwordx {
			float: left;
			text-align: center;
			font-size: 0.9em;
			color: #898989;
			a {
				cursor: pointer;
				color: #232323;
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.forgot_password{
			font-size: 0.75em;
			float: left;
			//margin-left: -1.1em;
		}

		.logo {
			width: 100%;
			height: 44px;
			background: url(/assets/img/global-header-logo.png);
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			margin: 20px 0px;
		}
		.error_message {
			text-align: center;
			font-size: 0.8em;
			color: #898989;
			font-style: italic;
			display: block;
		}

		.oceanview {
			/*
			display: flex;
			align-items: center;
			position: absolute;
			left: 50%;
			transform: translate(-50%, 80%);*/
			text-align: center;
			font-size: 1.7em;
			//margin-left: 1em;
			//margin-right: 2.5em !important;
			letter-spacing: 0.25em;
			font-weight: 600;
			margin-bottom: 1em;
			cursor: pointer;
		}
	}
}
/*
.clearfix {
	clear: both;
}*/
.shadow-underline
{
	box-shadow: 0 2px 4px -4px black;
}

// global border debugging util
/*
{ 
	outline: 1px solid rgba(255, 0, 0, 0.25);
	outline-offset: -1px;
}
*/
// https://www.angularjswiki.com/material/tooltip/#adding-multiline-tooltip-using-mat-tooltip
// mat-tooltip class
.multiline-tooltip {
	white-space: pre-line !important;
	line-height: 100%;
}

.infoPanelMargin {
	padding:40px 0;
}