@use "./../../theme.scss" as theme;
@use '~@angular/material' as mat;

$bg:theme.$bg;
$fg:theme.$fg;
$bg-shade-digit:theme.$bg-shade-digit;
$txt_note:theme.$txt_note;

$warn:theme.$warn;
$primary:theme.$primary;
$accent:theme.$accent;
$green:theme.$green;

$red: #EB3C39;
$orange: #FF7200;
$yellow: #EDDB00;
// $green: #4ECA00;
$black: #000000;
$white: #FFFFFF;

$green_light: #13df73;
$amber_light: #f3b100;
$red_light: #df135b;

$green_button: #72c639;

$dark: rgba(0,0,0,0.75);
$medium: rgba(0,0,0,0.5);
$light: rgba(0,0,0,0.25);
$off-white: rgba(0,0,0,0.05);

$control-bar__height: 4em;
$row-height: 3em;
$row-margin: 1em;
$col-width: 10em;
$col-margin: 1em;

$header_height: 80px;
$subheader_height: 142px;
$footer_height: 30px;
$middle_section_top: #{0 + $subheader_height};
$middle_section_diff: #{$header_height + $footer_height};

@mixin breakpoint($num){
	@media only screen and (max-width: #{$num}px){
		@content;
	}
}

@mixin breakpoint-min($num){
	@media only screen and (min-width: #{$num}px){
		@content;
	}
}

$shadowSize:4px;
$shadowBlur:4px;
$shadowColor:#00000055;
$borderColor:lightgrey;

$approvalGreen: #34ac4e;




//creative states 
$state-master: #A30000;
$state-deliver: #D4FFB9;
$state-complete: #DEFADC;
$state-approved: #dcfaf0;
$state-approve: #FFF5BC;
$state-check: #FDE6CB;
$state-qualifying: #FDE6CB;
$state-amend: #FFD6D6;
$state-submitted: #ffd7bc;
$state-build: #D6E5FF;
$state-create: #E2F6FF;
$state-queried: #f6e2ff;
$state-warning: #eee;
$state-info: #94d3d3;

//amend labels
$amendlabeOpacity: 0.1;
$amend-rejected: rgba(237,46,0,$amendlabeOpacity);// #ed2f00;
$amend-2: rgba(255,118,0,$amendlabeOpacity);//#FF7600;
$amend-new: rgba(255,186,0,$amendlabeOpacity);// #FFBA00;
$amend-4: rgba(235,96,180,$amendlabeOpacity);// #EB60B4;
$amend-queried: rgba(199,91,247,$amendlabeOpacity);// #C75BF7;
$amend-pending: rgba(253,230,203,0.5);// #FDE6CB
$amend-6: rgba(52,73,209,$amendlabeOpacity);// #3449D1;
$amend-todo: rgba(0,167,255,$amendlabeOpacity);// #00A7FF;
$amend-done: rgba(73,224,235,$amendlabeOpacity);// #49E0EB;
$amend-confirmed: rgba(209,247,57,$amendlabeOpacity); //#D1F739;
$amend-completed: rgba(0,147,31,0.52); //#37D458;
$amend-dead: white;//rgba(71,71,87,$amendlabeOpacity); //#494949;

//amend outlines
$amend-state-unsaved: $warn;
$amend-state-queried: purple;
$amend-state-todo: $primary;
$amend-state-done: $green;
$amend-state-denied: #000000;

			