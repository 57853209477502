.c-group{
	display: block;
}

.c-group__container{
	max-height: $row-height;
	overflow: hidden;
	&.is-open{
		max-height: 1000px;
	}
}

.c-group__label{
	font-weight: bold;
	cursor: pointer;
	height: $row-height;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background: #FFF;
	i{
		position: absolute;
		left: 1em;
		top: 50%;
		transform:translateY(-50%);
		transition: all .5s;
		.is-open &{
			transform: translateY(-50%) rotateX(180deg);
		}
	}
}