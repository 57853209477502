.c-cell{
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
}

.c-cell__inner{
	background: #000;
	color: #FFF;
	padding: 1em .5em;
	grid-row-start: 1;
	grid-row-end: 2;
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}

.c-cell__status{
	border-radius: 50%;
	width: 3em;
	height: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 1em;
	font-size: .75em;
	color: #000;
}

.c-cell__amends{
	position: absolute;
	top: -.5em;
	right: -.5em;
	background: #000;
	border-radius: 50%;
	width: 2em;
	height: 2em;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #FFF;
	font-size: .75em;
	color: #FFF;
}

.c-cell__status--complete{
	background: $green;
}

.c-cell__status--begin{
	background: $orange;
}
.c-cell__status--approved{
	background: $yellow;
}
.c-cell__status--awaiting{
	background: $red;
}

.c-cell__label{
	display: block;
	width: 100%;
}
.c-cell__users{
	display: block;
	width: 100%;
}