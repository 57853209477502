.o-multi-select{
	position: absolute;
	right: 1em;
	top: 50%;
	transform: translateY(-50%);
	width: 2em;
	height: 2em;
	background: #FFF;
	display: block;
	cursor: pointer;
	i{
		color: #000;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}