.c-breadcrumbs{
	width: auto;
	padding: 0 1em;
	i{
		font-size: 1em;
		vertical-align: middle;
		display: inline-block;
		color: $medium;
	}
	span{
		font-size: .75em;
		display: inline-flex;
		align-items: center;
		margin-bottom: .5em;
		color: $medium;
		&:last-of-type{
			font-size: 1em;
			display: block;
			margin: 0;
			color: #000;
		}
	}
}

.c-breadcrumbs__crumb{
	text-decoration: none;
	margin: 0 .5em;
	color: inherit;
	&:hover{
		color: $dark;
	}
}