// _project-grid.scss

.c-project-grid{
	min-width: 100%;
	height: 100%;
	display: block;
	position: relative;
	background: #FFF;
	&.is-fullscreen{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		width: 100vw;
	}
}