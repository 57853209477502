.c-specs__head{
	border-bottom: 1px solid $medium;
	padding: 1em;
	position: relative;
	text-align: left;
}

.c-specs__title{
	font-size: 2em;
}

.c-specs__head-icons{
	position: absolute;
	right: 1em;
}

.c-specs__table{
	width: 100%;
	display: block;
	color: #000;
}

.c-specs__table-row{
	display: flex;
	padding: 1em;
	width: 100%;
}

.c-specs__table-cell{
	flex-basis: 40%;
	text-align: left;
	color: $medium;
	&:last-of-type{
		color: #000;
		text-align: center;
		flex-basis: 20%;
	}
}

.c-specs__table-notes{
	width: 70%;
	display: block;
	&:first-of-type{
		color: $medium;
		width: 30%;
	}
	color: $medium;
}

.c-specs__history-title{
	color: $medium;
	display: block;
}

.c-specs__history{
	.c-specs__table-row{
		align-items: center;
		justify-content: flex-start;
	}
	.c-specs__table-cell{
		text-align: left;
		width: auto;
		display: inline-block;
		height: 100%;
		flex-grow: 2;
		flex-basis: 90%;
		&:first-of-type{
			flex-basis: 10%;
			flex-grow: 1;
		}
		div{
			margin-left: 1em;
			color: $medium;
			line-height: 1.5;
			&:first-of-type{
				color: #000;
				font-weight: bold;
			}
		}
	}
}